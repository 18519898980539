import React, { Fragment, useState } from 'react';
import { Link, navigate } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import { Formik, Form, Field } from 'formik';
import { StyledWrapper } from '../../../containers/SignIn/SignIn.styles';
import logo from '../../../components/images/logo.png';
import loading from '../../../components/images/loading.gif';
import AppContext, { UserContext } from '../../../components/AppContext';

const SignIn = ({ location }) => {
  // const auth = useSelector(state => state.firebase.auth);

  // const authError = useSelector(state => state.auth.authError);

  // const dispatch = useDispatch();

  const validateEmail = value => {
    let error;

    if (!value) {
      error = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  };

  const validatePassword = value => {
    let error;

    if (!value) {
      error = 'Passoword is required';
    } else if (value.length < 8) {
      error = 'Passoword should be atleast 8 characters';
    }
    return error;
  };

  const signInUser = values => {
    firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then(auth => {
        setButtonState(false);
        if (auth.user.uid && auth.user.emailVerified) {
          if (location.search == '?q=WindFrame') {
            navigate('/app');
          } else {
            navigate('/');
          }
        } else if (auth.user.uid) {
          navigate('/app/confirm');
        }
      });
  };

  // if (auth.uid && auth.emailVerified) {
  //   return <Redirect to="/dashboard" />;
  // } else if (auth.uid) {
  //   return <Redirect to="/confirm" />;
  // }

  return (
    <AppContext>
      <UserContext.Consumer>
        {({ requestLogin, error, buttonState, setButtonState }) => (
          <Fragment>
            <StyledWrapper>
              <div className="box mb-5">
                <Link to="/">
                  <div className="logo">
                    <img src={logo} alt="devwares" />
                  </div>
                </Link>
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  onSubmit={values => {
                    setButtonState(true);
                    requestLogin(values.email, values.password, location);
                  }}
                >
                  {({
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    /* and other goodies */
                  }) => (
                      <Form className="form login">
                        <div className="form-field">
                          <Field
                            type="email"
                            name="email"
                            className="form-input"
                            placeholder="Email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            validate={validateEmail}
                          />
                        </div>
                        <div className="red-text">
                          {errors.email && touched.email && errors.email}
                        </div>

                        <div className="form-field">
                          <Field
                            type="password"
                            name="password"
                            className="form-input"
                            placeholder="Password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            validate={validatePassword}
                          />
                        </div>
                        <div className="red-text">
                          {errors.password && touched.password && errors.password}
                        </div>
                        <span>
                          <span className="span" style={{ color: '#3329e0' }}>
                            <Link to="/app2/forgot-password">Forgot Password?</Link>
                          </span>
                        </span>
                        <div className="form-submit">
                          {buttonState && (
                            <img src={loading} alt="loading gif" style={{ margin: 'auto' }} />
                          )}
                          {!buttonState && (
                            <input type="submit" disabled={buttonState} value="Login" />
                          )}
                        </div>
                        <div className="red-text">{error ? <p>{error} </p> : null}</div>
                        <span className="span">
                          Don't have an Account?
                        <span className="ml-2">
                            <Link
                              to={
                                location.search == '?q=WindFrame'
                                  ? '/app2/signup?q=WindFrame'
                                  : '/app2/signup'
                              }
                            >
                              Sign Up for Free
                          </Link>
                          </span>
                        </span>
                      </Form>
                    )}
                </Formik>
              </div>
            </StyledWrapper>
          </Fragment>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default SignIn;